import React, { useRef, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';

// Add the icons to the library
library.add(faArrowLeft, faArrowRight);

const BookModal = ({ selectedBook, nextBook, prevBook, closeModal }) => {
  const amazonButtonRef = useRef(null);
  const [userBookStatus, setUserBookStatus] = useState({
    read_my_book: false,
    own_my_physical: false,
    own_my_audible: false
  });

  useEffect(() => {
    if (amazonButtonRef.current) {
      amazonButtonRef.current.focus();
    }
  }, [selectedBook]);

  useEffect(() => {
    // Reset the state to default values
    setUserBookStatus({
      read_my_book: selectedBook ? selectedBook.read_my_book : false,
      own_my_physical: selectedBook ? selectedBook.own_my_physical : false,
      own_my_audible: selectedBook ? selectedBook.own_my_audible : false
    });

    // Fetch user's book status if logged in
    const fetchUserBookStatus = async () => {
      try {
        const response = await axios.get(`/books/${selectedBook.id}/user_book`);
        setUserBookStatus(response.data);
      } catch (error) {
        console.error('Error fetching user book status:', error);
        // Fallback to book's default values
        setUserBookStatus({
          read_my_book: selectedBook.read_my_book || false,
          own_my_physical: selectedBook.own_my_physical || false,
          own_my_audible: selectedBook.own_my_audible || false
        });
      }
    };

    if (selectedBook && selectedBook.id) {
      fetchUserBookStatus();
    }
  }, [selectedBook]);

  const handleStatusChange = async (e) => {
    const { name, checked } = e.target;
    const updatedStatus = {
      ...userBookStatus,
      [name]: checked
    };
    setUserBookStatus(updatedStatus);

    // Automatically save the status when any checkbox is changed
    try {
      await axios.patch(`/books/${selectedBook.id}/user_book`, { user_book: updatedStatus });
      // No need to show a popup or alert
    } catch (error) {
      console.error('Error updating status:', error);
      // Optionally handle error state here if necessary
    }
  };

  const saveStatus = async () => {
    try {
      await axios.patch(`/books/${selectedBook.id}/user_book`, { user_book: userBookStatus });
      alert('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status');
    }
  };

  if (!selectedBook) return null;

  return (
    <div>
      <div style={{ fontSize: '14px', letterSpacing: '-0.3px' }}>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            variant="light"
            onClick={prevBook}
            style={{
              border: '0px none',
              backgroundColor: 'transparent',
              width: 'auto',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#555', fontSize: '2rem' }} />
          </Button>
          {selectedBook.image_url && (
            <LazyLoadImage src={selectedBook.image_url} alt={selectedBook.name} effect="blur" style={{ height: '200px' }} />
          )}
          <Button
            variant="light"
            onClick={nextBook}
            style={{
              border: '0px none',
              backgroundColor: 'transparent',
              width: 'auto',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} style={{ color: '#555', fontSize: '2rem' }} />
          </Button>
        </div>
        <p><strong>Category:</strong> {selectedBook.category}</p>
        <p><strong>Name:</strong> {selectedBook.name}
          <br />
          <strong>Author:</strong> {selectedBook.author}
        </p>
        <p>
          <strong>Mentioned In:</strong> <a href={selectedBook.mentioned_in_url} target="_blank" rel="noopener noreferrer">{selectedBook.mentioned_in}</a>
        </p>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="readMyBook"
            name="read_my_book"
            checked={userBookStatus.read_my_book}
            onChange={handleStatusChange}
          />
          <label className="form-check-label" htmlFor="readMyBook">Read Book</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="ownMyPhysical"
            name="own_my_physical"
            checked={userBookStatus.own_my_physical}
            onChange={handleStatusChange}
          />
          <label className="form-check-label" htmlFor="ownMyPhysical">Own Physical Copy</label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="ownMyAudible"
            name="own_my_audible"
            checked={userBookStatus.own_my_audible}
            onChange={handleStatusChange}
          />
          <label className="form-check-label" htmlFor="ownMyAudible">Own Audible Copy</label>
        </div>
      </div>
      <div className="modal-footer">
        <Button variant="secondary" onClick={closeModal}>Close</Button>
        {selectedBook && (
          <Button
            href={selectedBook.amz_link}
            target="_blank"
            variant="primary"
            ref={amazonButtonRef}
          >
            Go to Amazon
          </Button>
        )}
      </div>
    </div>
  );
};

export default BookModal;
