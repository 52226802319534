// axiosConfig.js
import axios from 'axios';

// Get CSRF token from meta tag
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

// Set default headers
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

export default axios;
