import React from 'react';
import { createRoot } from 'react-dom/client';
import BookGallery from './components/BookGallery';
import { Turbo } from "@hotwired/turbo-rails";
import Rails from "@rails/ujs"
Rails.start()

import "bootstrap";

document.addEventListener('turbo:load', function() {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <BookGallery />
      </React.StrictMode>
    );
  }
});
