import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';

const AnimatedModal = ({ showModal, closeModal, children }) => {
  const modalRef = useRef(null);
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (showModal) {
      const imageElement = document.querySelector('.trigger-image'); // Adjust the selector based on your image

      if (imageElement && modalRef.current) {
        const { x, y, width, height } = imageElement.getBoundingClientRect();
        const contentRect = modalRef.current.getBoundingClientRect();

        setContentSize({ width: contentRect.width, height: contentRect.height });

        gsap.fromTo(
          modalRef.current,
          {
            x: x,
            y: y,
            width: width,
            height: height,
            opacity: 0,
            rotationY: 0,
            rotationX: 0,
            scale: 0.1,
          },
          {
            x: window.innerWidth / 2 - contentRect.width / 2,
            y: window.innerHeight / 2 - contentRect.height / 2,
            width: contentRect.width,
            height: contentRect.height,
            opacity: 1,
            rotationX: 360,
            scale: 1,
            duration: 0.5,
            ease: 'power4.inOut',
          }
        );
      }
    }
  }, [showModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal, closeModal]);

  return (
    <div
      className={`modal ${showModal ? 'is-active' : ''}`}
      style={{
        display: showModal ? 'block' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        overflow: 'hidden',
      }}
    >
      <div
        ref={modalRef}
        className="modal-content"
        style={{
          position: 'relative',
          background: 'white',
          width: contentSize.width || '80%',
          maxWidth: '800px',
          height: contentSize.height || 'auto',
          padding: '20px',
          borderRadius: '8px',
          overflow: 'auto',
        }}
      >
        {children}
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default AnimatedModal;
