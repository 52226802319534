import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BookForm = ({
  index,
  book,
  isEditMode,
  updateBook,
  handleDragOver,
  handleDrop,
  handleImageUpload,
  removeBook,
  openModal
}) => {
  return (
    <div
      key={index}
      className="project-card"
      style={{ backgroundColor: book.read_book ? '#ddffdd' : 'transparent' }}
    >
      {isEditMode ? (
        <>
          <div
            className="image-drop-zone"
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {book.image_url ? (
              <LazyLoadImage
                src={book.image_url}
                alt={book.name}
                effect="blur"
              />
            ) : (
              <p>Drag & drop an image here</p>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(index, e.target.files[0])}
            id={`imageUpload-${index}`}
            style={{ display: 'none' }}
          />
          <label htmlFor={`imageUpload-${index}`} className="upload-button">
            Upload Image
          </label>
          <input
            value={book.name}
            onChange={(e) => updateBook(index, 'name', e.target.value)}
            className="project-name-input"
          />
          <textarea
            value={book.description}
            onChange={(e) => updateBook(index, 'description', e.target.value)}
            className="project-description-input"
          />
          <input
            value={book.mentioned_in}
            onChange={(e) => updateBook(index, 'mentioned_in', e.target.value)}
            placeholder="Mentioned in"
            className="project-mentioned-input"
          />
          <input
            value={book.amz_link}
            onChange={(e) => updateBook(index, 'amz_link', e.target.value)}
            placeholder="Amazon Link"
            className="project-link-input"
          />
          <input
            value={book.link_audible}
            onChange={(e) => updateBook(index, 'link_audible', e.target.value)}
            placeholder="Link to Audible"
            className="project-link-audible-input"
          />
          <select
            value={book.category}
            onChange={(e) => updateBook(index, 'category', e.target.value)}
            className="project-category-select"
          >
            <option value="Tim Ferriss">Tim Ferriss</option>
            <option value="Russell Brunson">Russell Brunson</option>
            <option value="Eben Pagan">Eben Pagan</option>
          </select>
          <div className="project-checkboxes">
            <label>
              <input
                type="checkbox"
                checked={book.read_book}
                onChange={(e) => updateBook(index, 'read_book', e.target.checked)}
              />
              Read
            </label>
            <label>
              <input
                type="checkbox"
                checked={book.own_physical}
                onChange={(e) => updateBook(index, 'own_physical', e.target.checked)}
              />
              Own Physical
            </label>
            <label>
              <input
                type="checkbox"
                checked={book.own_audible}
                onChange={(e) => updateBook(index, 'own_audible', e.target.checked)}
              />
              Own Audible
            </label>
          </div>
          <button onClick={() => removeBook(index)}>Remove</button>
        </>
      ) : (
        <>
          {book.image_url && (
            <LazyLoadImage
              src={book.image_url}
              alt={book.name}
              onClick={() => openModal(index)}
              onError={() =>
                console.log(`Image load error for book ${book.name} with URL ${book.image_url}`)
              }
              effect="blur"
              style={{ cursor: 'pointer' }}
              className="trigger-image"
            />
          )}
          <h6 className="fw-bold text-uppercase">{book.name}</h6>
        </>
      )}
    </div>
  );
};

export default BookForm;
