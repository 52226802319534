import React, { useState, useEffect, useCallback } from 'react';
import axios from './axiosConfig';
import BookForm from './BookForm';
import BookModal from './BookModal';
import AnimatedModal from './AnimatedModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faEyeSlash, faEye, faFilter } from '@fortawesome/free-solid-svg-icons';

const BookGallery = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [books, setBooks] = useState([]);
  const [booksToDelete, setBooksToDelete] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFilteredBookIndex, setSelectedFilteredBookIndex] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hideReadBooks, setHideReadBooks] = useState(false);
  const [hideOwnedBooks, setHideOwnedBooks] = useState(false);
  const [hideAudibleBooks, setHideAudibleBooks] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // Track admin status
  const [userImage, setUserImage] = useState('');

  useEffect(() => {
    fetchBooks();
    checkAuthStatus();
  }, []);

  const fetchBooks = async () => {
    try {
      const response = await axios.get('/books.json', { params: { user: true } });
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

const checkAuthStatus = async () => {
  try {
    const response = await axios.get('/auth/status');
    const { isAuthenticated, user } = response.data;
    setIsAuthenticated(isAuthenticated);
    if (user) {
      setIsAdmin(user.isAdmin);
      setUserImage(user.image); // Set the user's image URL
      console.log("User image URL:", user.image); // Debugging: Check if the image URL is correct
    }
  } catch (error) {
    console.error('Error checking authentication status:', error);
  }
};

  const toggleEditMode = async () => {
    if (!isAdmin) return; // Only allow admins to toggle edit mode
    if (isEditMode) {
      await saveBooks();
    }
    setIsEditMode(!isEditMode);
  };

  const updateBook = (index, field, value) => {
    setBooks((prevBooks) => {
      const updatedBooks = [...prevBooks];
      if (updatedBooks[index]) {
        updatedBooks[index][field] = value;
      }
      return updatedBooks;
    });
  };

  const handleImageUpload = useCallback((index, file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => updateBook(index, 'image_url', e.target.result);
      reader.readAsDataURL(file);
      const updatedBooks = [...books];
      updatedBooks[index].imageFile = file;
      setBooks(updatedBooks);
    }
  }, [books]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleImageUpload(index, files[0]);
    }
  }, [handleImageUpload]);

  const addBook = () => {
    if (!isAdmin) return; // Only allow admins to add books
    setBooks([...books, { name: 'New Book', description: 'Description here', image_url: '', mentioned_in: '', amz_link: '', link_audible: '', category: 'Tim Ferriss', read_book: false, own_physical: false, own_audible: false }]);
  };

  const removeBook = (index) => {
    if (!isAdmin) return; // Only allow admins to remove books
    const bookToRemove = books[index];
    if (bookToRemove.id) {
      setBooksToDelete((prevBooksToDelete) => [...prevBooksToDelete, bookToRemove.id]);
    }
    setBooks(books.filter((_, i) => i !== index));
  };

  const saveBooks = async () => {
    try {
      for (const bookId of booksToDelete) {
        await axios.delete(`/books/${bookId}.json`);
      }
      for (const book of books) {
        const formData = new FormData();
        formData.append('book[name]', book.name);
        formData.append('book[description]', book.description);
        formData.append('book[mentioned_in]', book.mentioned_in);
        formData.append('book[amz_link]', book.amz_link);
        formData.append('book[link_audible]', book.link_audible);
        formData.append('book[category]', book.category);
        formData.append('book[read_book]', book.read_book);
        formData.append('book[own_physical]', book.own_physical);
        formData.append('book[own_audible]', book.own_audible);
        if (book.imageFile) {
          formData.append('book[image]', book.imageFile);
        }

        if (book.id) {
          await axios.put(`/books/${book.id}.json`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await axios.post('/books.json', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      }
      setBooksToDelete([]);
      fetchBooks();
    } catch (error) {
      console.error('Error saving books:', error);
    }
  };

  const openModal = (filteredIndex) => {
    setSelectedFilteredBookIndex(filteredIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFilteredBookIndex(null);
  };

  const nextBook = () => {
    setSelectedFilteredBookIndex((prevIndex) => (prevIndex + 1) % filteredBooks.length);
  };

  const prevBook = () => {
    setSelectedFilteredBookIndex((prevIndex) => (prevIndex - 1 + filteredBooks.length) % filteredBooks.length);
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowRight') {
      nextBook();
    } else if (e.key === 'ArrowLeft') {
      prevBook();
    } else if (e.key === 'Escape') {
      closeModal();
    }
  }, [nextBook, prevBook, closeModal]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal, handleKeyDown]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      await axios({
        method: 'delete',
        url: '/users/sign_out',
        headers: {
          'X-CSRF-Token': csrfToken,
        }
      });
      setIsAuthenticated(false);
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const filteredBooks = books
    .filter(book => !hideReadBooks || !book.read_my_book_status)
    .filter(book => !hideOwnedBooks || !book.own_my_physical_status)
    .filter(book => !hideAudibleBooks || !book.own_my_audible_status)
    .filter(book => selectedPodcast === '' || book.category === selectedPodcast);

  const selectedBook = selectedFilteredBookIndex !== null ? filteredBooks[selectedFilteredBookIndex] : null;

  const totalBooksInCategory = selectedPodcast
    ? books.filter(book => book.category === selectedPodcast).length
    : books.length;

  const filteredBooksCount = filteredBooks.length;

  const navigateToHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="project-gallery">

<header className="d-flex justify-content-between align-items-center mb-3">
  <h3 className="fw-bold">
    <button
      onClick={navigateToHome}
      style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
      📚
    </button>
    Books Tim Ferriss recommends on his Podcast
  </h3>

  <nav className="d-flex align-items-center">
    <ul className="d-flex justify-content-between align-items-center flex-wrap mb-0">
      <li className="me-3">Work</li>
      <li className="me-3">Clients</li>
      <li className="me-3">Services</li>
      <li className="me-3">About</li>
      <li className="me-3">Blog</li>
    </ul>
    {isAdmin && (
      <button onClick={toggleEditMode} className="edit-toggle btn btn-sm btn-secondary me-2">
        {isEditMode ? 'Save' : 'Edit'}
      </button>
    )}
    {isAuthenticated ? (
      <>
        <button onClick={handleLogout} className="btn btn-sm btn-primary me-2">
          <FontAwesomeIcon icon={faSignOutAlt} /> Logout
        </button>
        {userImage && (
          <img
            src={userImage}
            alt="User Avatar"
            className="rounded-circle"
            style={{ width: '40px', height: '40px', objectFit: 'cover' }}
          />
        )}
      </>
    ) : (
      <form action="/users/auth/google_oauth2" method="post" data-turbo="false" className="d-inline">
        <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute('content')} />
        <button type="submit" className="btn btn-sm btn-primary d-inline d-md-none">
          <FontAwesomeIcon icon={faUser} />
        </button>
        <button type="submit" className="btn btn-sm btn-primary d-none d-md-inline">Login</button>
      </form>
    )}
  </nav>



</header>


      <div className="d-flex mb-3">
        <button
          onClick={() => setHideReadBooks(!hideReadBooks)}
          className="btn btn-sm btn-secondary me-2 d-flex align-items-center"
        >
          <FontAwesomeIcon icon={hideReadBooks ? faEye : faEyeSlash} className="me-1" />
          {hideReadBooks ? 'Show All Books' : 'Hide Read Books'}
        </button>
        <button
          onClick={() => setHideOwnedBooks(!hideOwnedBooks)}
          className="btn btn-sm btn-secondary me-2 d-flex align-items-center"
        >
          <FontAwesomeIcon icon={hideOwnedBooks ? faEye : faEyeSlash} className="me-1" />
          {hideOwnedBooks ? 'Show All Owned Books' : 'Hide Books I Own'}
        </button>
        <button
          onClick={() => setHideAudibleBooks(!hideAudibleBooks)}
          className="btn btn-sm btn-secondary me-2 d-flex align-items-center"
        >
          <FontAwesomeIcon icon={hideAudibleBooks ? faEye : faEyeSlash} className="me-1" />
          {hideAudibleBooks ? 'Show All Audible Books' : 'Hide Audible Books I Own'}
        </button>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-secondary dropdown-toggle"
            type="button"
            id="categoryDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faFilter} className="me-1" />
            Filter by Category
          </button>
          <ul className="dropdown-menu" aria-labelledby="categoryDropdown">
            <li>
              <button
                className="dropdown-item"
                onClick={() => setSelectedPodcast('')}
              >
                All Categories
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => setSelectedPodcast('Tim Ferriss')}
              >
                Tim Ferriss
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => setSelectedPodcast('Russell Brunson')}
              >
                Russell Brunson
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="mb-3">
        {hideReadBooks || hideOwnedBooks || hideAudibleBooks
          ? <p>{filteredBooksCount} books left</p>
          : selectedPodcast
            ? <p>{totalBooksInCategory} books in this category</p>
            : <p>{totalBooksInCategory} books total</p>}
      </div>

      <div className={`projects-grid ${isEditMode ? 'edit-mode' : 'preview-mode'}`}>
        {filteredBooks.map((book, index) => (
          <BookForm
            key={index}
            index={index}
            book={book}
            isEditMode={isEditMode}
            updateBook={updateBook}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleImageUpload={handleImageUpload}
            removeBook={removeBook}
            openModal={() => openModal(index)}
          />
        ))}
      </div>

      {isEditMode && isAdmin && <button onClick={addBook} className="add-project">Add Book</button>}

      <footer>{/* Footer content */}</footer>

      <AnimatedModal
        showModal={showModal}
        closeModal={closeModal}
      >
        <BookModal
          showModal={showModal}
          closeModal={closeModal}
          selectedBook={selectedBook}
          nextBook={nextBook}
          prevBook={prevBook}
        />
      </AnimatedModal>
    </div>
  );
};

export default BookGallery;
